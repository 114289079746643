<template>
    <v-card class="pa-0">
      
      <v-toolbar min-width="100%" dark color="primary" class="d-flex align-end">
        <v-card-title>Show Attendance Detail</v-card-title>
        <div class="flex-grow-1 text-right">{{ currentDate }}</div>
      </v-toolbar>
      <v-card-text>
        <p></p>
        <p></p>
      </v-card-text>
  
      
  
      <DataTableSSR
        apiEndPoint="/check_attendance/"
        :headers="headers"
        :instantLoad="true"
        :items="attendanceData"
      >
        <template #[`item.access_id`]="{ item }">{{ item.access_id }}</template>
  
        <template #[`item.attend_user`]="{ item }">{{ item.attend_user_name }}</template>
        <template #[`item.rooms`]="{ item }">{{ item.room_name }}</template>
        <template #[`item.log_time`]="{ item }">{{ new Date(item.log_time).toLocaleDateString()  }}</template>
  
        <template #[`item.check_in`]="{ item }">{{ item.check_in ? new Date(item.check_in).toLocaleTimeString('en-US', { timeZone: 'Asia/Dhaka', hour12: true, hourCycle: 'h23' }).replace(/:\d{2}\s/, ' ') : '-' }}</template>

<template #[`item.check_out`]="{ item }">{{ item.check_out ? new Date(item.check_out).toLocaleTimeString('en-US', { timeZone: 'Asia/Dhaka', hour12: true, hourCycle: 'h23' }).replace(/:\d{2}\s/, ' ') : '-' }}</template>

        
      </DataTableSSR>
    </v-card>
  </template>
  
  <script>
  import DataTableSSR from "../../../components/global/DataTableSSR.vue";
  
  export default {
    components: { DataTableSSR },
    data() {
      return {
        checkedIn: false,
        checkedOut: false,
        // checkInTime: null,
        checkOutTime: null,
        timer: null,
        elapsedSeconds: 0,
        headers: [
          { text: "Access ID", value: "access_id" },
          { text: "Date", value: "log_time" },
  
          { text: "Name", value: "attend_user" },
          { text: "Class", value: "rooms" },
  
  
  
          { text: "Check In ", value: "check_in" },
          { text: "Check Out ", value: "check_out" },
         
        ],
        currentDate: new Date().toLocaleString(),
      };
    },
    mounted() {
      setInterval(() => {
        this.currentDate = new Date().toLocaleString();
      }, 1000);
    },
  };
  </script>
  
  <style></style>
  